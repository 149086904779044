

.workingHoursWrapper {
  display: flex;
  justify-content: flex-start;
}

.workingHours {
  display: block;
  width: 110px;
}
