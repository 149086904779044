:root {
  --primary-color: #2290d6;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

.sidebar {
  padding: 32px 10px;
  border-right: 1px solid #eaecf0;
}
.page-title {
  color: var(--primary-color);
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

.profile-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.ant-tabs {
  width: 100%;
}

.table {
  border-collapse: collapse !important;
  width: 100%;
  text-align: left !important;
}
.table tr {
  border-bottom: 1px solid #ddd !important;
  padding: 10px 0 !important;
}

.table td,
.table th {
  padding: 15px 8px !important;
}

.profile-button {
  z-index: 10;
  border-color: #4c4c4c;
}

.ant-menu {
  border-inline-end: none !important;
  background-color: #fafafa;
}

.content-wrapper {
  background-color: #fff;
  border-radius: 20px;
  height: calc(100vh - 100px);
  margin: 10px;
  padding: 10px 20px;
}

.content-wrapper.statistics {
  background-color: rgb(250, 250, 250);
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.menu-button {
  border: none;
  background-color: #fafafa;
  box-shadow: none;
}

.menu-button span {
  font-size: 20px;
}

.input-error {
  border: 1px solid #ff4d4f;
}

.ant-list-items {
  height: calc(100vh - 350px);
  overflow: scroll;
}

.statistics th.ant-table-cell {
  background-color: white !important;
}

.recharts-cartesian-axis-tick-value {
  font-size: 8px !important;
}

.statistics .ant-badge-status-text {
  font-size: 12px !important;
}

.statistics .ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}

.statistics .ant-badge-status-text {
  margin-top: -5px !important;
}

.statistics .recharts-default-tooltip {
  border: none !important;
  color: white !important;
  background-color: #4c4c4c !important;
  border-radius: 5px !important;
}
