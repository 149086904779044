.drawer {
  width: calc(100% - 280px);
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.addButton {
  margin-right: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.formInner {
  width: 100%;
  justify-content: space-between;
  height: calc(100% - 120px);
  overflow: scroll;
}
