.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -80px;
  align-self: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.formInner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 325px;
  width: 100%;
}

.title {
  font-size: 20px;
  line-height: 1;
  color: #01a2e8;
  margin-bottom: 10px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  line-height: 20px;
  color: #4C4C4C;
  margin-bottom: 20px;
  text-align: center;
}

.button {
  width: 160px;
  display: block;
  margin: 0 auto;
}

.resetButton {
  display: block;
  margin: 0 auto;
  margin-top: 30px;
}

.linkToLogin {
  line-height: 1;
  text-align: center;
}
