.loginFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -80px;
  align-self: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.loginFormInner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 325px;
  width: 100%;
}

.title {
  font-size: 20px;
  line-height: 1;
  color: #01a2e8;
  margin-bottom: 24px;
  text-align: center;
}

.forgotPassword {
  align-self: flex-end;
}

.loginButton {
  width: 160px;
  display: block;
  margin: 0 auto;
}

.resetPassword {
  margin-top: 0;
  margin-bottom: 30px;
  align-self: flex-end;
  font-size: 12px;
}

.resetPasswordLink {
  font-size: 12px;
}

.loginField {
  margin-bottom: 20px;
}

.loginField:last-child {
  margin-bottom: 0;
}
