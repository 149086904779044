.form {
  background-color: #FAFAFA;
  padding: 0 20px;
  max-width: 470px;
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
}
