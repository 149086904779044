.list {
  background-color: white;
  color: #4c4c4c;
  margin: 12px 0 20px;
  padding: 20px 0;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  line-height: 16px;
}

.listItemInner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  position: relative;
}

.listItemInner:last-child {
  border-right: none;
}

.listItemIcon {
  position: absolute;
  left: 20px;
  top: 0;
}

.listItemInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 88px;
  justify-content: space-between;
  padding: 0 50px;
}

.listItemTitle {
  font-size: 14px;
  text-align: center;
  max-width: 140px;
  width: 100%;
  margin: 0;
}

.listItemAmount {
  font-size: 20px;
  font-weight: 700;
}

.ordersTable {
  background-color: white;
  border-radius: 5px;
  line-height: 16px;
  padding: 10px;
}

.pieTitle {
  margin: 0;
  margin-bottom: 30px;
  color: #4c4c4c;
}

.pie {
  display: flex;
  align-items: flex-start;
}

.pieInfoItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #4c4c4c;
}

.pieInfoValue {
  margin: 0;
  font-size: 12px;
  margin-left: 30px;
}

.chartWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-bottom: 20px;
}

.barChartWrapper {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  line-height: 1.6;
}

.pieScroll {
  margin-left: 80px;
}
