.icon {
  font-size: 20px!important;
  /* color: #2290d6!important; */
}

.item {
  display: flex!important;
  align-items: center!important;
}

.header {
  display: flex;
  align-items: center;
}

.sidebar {
  text-align: 'center';
  line-height: '120px';
  color: '#fff';
  background-color: '#1677ff'
}
